@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", sans-serif !important;
}
.fm_inter {
  font-family: "Inter", sans-serif !important;
}
.focus_outline__none:focus-visible {
  outline: none;
}
/* FONT SIZES */
.fs_11 {
  font-size: 11px;
}
.fs_12 {
  font-size: 12px;
}
.fs_14 {
  font-size: 14px;
}
.fs_15 {
  font-size: 15px;
}
.fs_16 {
  font-size: 14px;
}
.fs_18 {
  font-size: 18px;
}
.fs_20 {
  font-size: 20px;
}
.fs_22 {
  font-size: 22px;
}
.fs_26 {
  font-size: 26px;
}
/* Added for login logo */
.fs_32 {
  font-size: 38px;
}

/* FONT WEIGHTS */
.fw_100 {
  font-weight: 100;
}
.fw_200 {
  font-weight: 200;
}

.fw_300 {
  font-weight: 300;
}
.fw_400 {
  font-weight: 400;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fw_700 {
  font-weight: 700;
}
.fw_800 {
  font-weight: 800;
}
.fw_900 {
  font-weight: 900;
}

.settings-table-header {
  border-bottom: 1px solid #ebeaf2;
  border-top: 1px solid #ebeaf2;
  padding: 15px;
}

.setting-table-cell {
  border-bottom: 1px solid #ebeaf2;
  padding: 15px;
}

.active-tab-style {
  /* border: none; */
  background-color: #277dfe0f;
  border-radius: 15px;
  width: 100%;
}

@media print {
  .print-header {
    display: block; /* Ensure header displays only once */
  }

  .print-footer {
    display: table-footer-group;
  }

  table,
  tr,
  th,
  td {
    page-break-inside: avoid;
  }

  @page {
    margin: 10mm;
  }
}
/* globals.css */
:root {
  /* Primary colors */
  --primary: #f15e25; /* This should be the RGB values (not HSL) for Tailwind to interpret */
  /* --primary: #277dfe; This should be the RGB values (not HSL) for Tailwind to interpret */
  --secondary: #0d111a; /* general text color */

  /* Green shades */
  --success-color: #027a48; /* success color */
  /* --excel-color: #21a366; */
  --kebab-selection-color: #c4eab7; /* kebab selection color */

  /* Blue shades */
  --row-selection-color: #d5e6ff; /* row selection color */
  --kebab-plus-button: #aecaff; /* kebab plus button */
  --button-hover-color: #ed6f2480; /* button hover color */

  /* Red shades */
  --error-color: #f51d00; /* error color */
  --remove-color: #f94b4b; /* remove color, e.g., disable group */
  --edit-color: #ffba08; /* edit color for disable group */

  /* Black shades */
  --popup-overlay-color: black; /* popup overlay color */
  --heading-color: #000000; /* heading color */
  --sidebar-selected-color: #212d46; /* sidebar and pagination selected color */

  /* Gray shades */
  --description-color: #787878; /* description text */
  --button-border-color: #d0d5dd; /* button border, e.g., plus, popover table */
  --table-heading-color: #475467; /* table heading color */
  --placeholder-color: #667085; /* placeholder text */
  --status-draft-open-color: #344054; /* draft or open status color */

  /* White and light shades */
  --table-heading-background: #f2f4f5; /* table heading background */
  --tertiary-color: #ffffff; /* tertiary color (white) */
  --row-hover-color: #f3f8ff; /* row hover color */
  --toggle-disabled-background: #f4f4f4; /* toggle plus button disabled background */
  --light-border-color: #eaecf0; /* light border, e.g., kebab dropdown border */
  --disabled-color: #e0e0e0; /* light border, e.g., kebab dropdown border */

  --label-color: #0d111a; /* light border, e.g., kebab dropdown border */
  --value-color: #0d111a; /* light border, e.g., kebab dropdown border */
  --placeholder-color: #667085; /* light border, e.g., kebab dropdown border */
  --focus-color: #277dfe;

  /* extra colors */

  --success-color-opacity: rgba(
    2,
    122,
    72,
    0.15
  ); /* 15% opacity success color */
  --danger-color-opacity: rgba(245, 29, 0, 0.15); /* 15% opacity danger color */
}
/* .color-9C9C9C {
  color: #9c9c9c;
}  */
/* .color-FF8A00 {
  color: #ff8a00;
} */
/* .color-51AF49 {
  color: #51af49;
} */
/* .color-F51D00 {
  color: #f51d00;
} */
/* .color-6C7184 {
  color: #6c7184;
} */
/* .primary-bg {
  background-color: #277dfe !important;
} */
/* .primary-bg-6 {
  background: #277dfe0f;
} */
/* .secondary-bg {
  background-color: #0d111a;
} */
/* .bg-F9F9FB {
  background-color: #f9f9fb;
} */
/* .bg-ECECEC {
  background-color: #ececec;
} */
/* .bg-F6F5F9 {
  background-color: #f6f5f9;
} */
/* .bg-EAECF0 {
  background: #EAECF0;
} */
/* .bg-f9fafb {
  background: #f9fafb;
} */
/* .bg-F4F4F4 {
  background: #F4F4F4;
} */
/* .bg-white {
  background-color: #ffffff;
} */
/* .bg-F9FAFB {
  background: #e0ebf7;
} */
/* .bg-F04438 {
  background: #f04438;
} */
/* .bg-black {
  background-color: black;
} */
/* .bg-FF4545 {
  background: #ff4545;
} */
/* .bg-FFBA08 {
  background: #ffba08;
} */
/* .bg-AECAFF {
  background: #aecaff;
} */
/* .border_6D6D6D80_1 {
  border: 1px solid #6d6d6d80;
} */
/* .border_D0D5DD_1 {
  border: 1px solid #d0d5dd;
} */
/* .border_D0D5DD_2 {
  border: 2px solid #d0d5dd;
} */
/* .border_277dfe_1 {
  border: 1px solid #277dfe;
} */
/* .border-D0D5DD {
  border-right: 2px solid #d0d5dd;
} */
/* .border_027A48_1 {
  border: 1px solid #027a48;
} */
/* .border_344054_1 {
  border: 1px solid #344054;
} */
/* .border_000000_1 {
  border: 1px dotted #000000;
} */
/* .border_ECECEC_1 {
  border: 1px solid #ececec;
} */
/* .border_51af49_1 {
  border: 1px solid #51af49;
} */
/* .border_f51d00_1 {
  border: 1px solid #f51d00;
} */
/* .border_2r_F3F3F3 {
  border-right: 2px solid #f3f3f3;
} */
/* .border_0D111A {
  border: #0d111a;
} */


.underline_000000 {
  border-bottom: 1px solid #000000; /* You can adjust the color and style as needed */
}

/* Add this to your global styles */
/* .scrollbar-rounded {
  scrollbar-width: thin;
} */

.scrollbar-rounded::-webkit-scrollbar {
  width: 6px; /* Make the scrollbar even thinner */
  height: 6px; /* Thin scrollbar horizontally as well */
}

.scrollbar-rounded::-webkit-scrollbar-thumb {
  background-color: #c4bdbd; /* Color of the thumb */
  border-radius: 12px; /* Add rounded corners */
  cursor: pointer;
}

.scrollbar-rounded::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background color */
}

.new-section {
  page-break-before: always;
}
