.disPerInput {
  color: #1d2939;
  font-size: 14px;
  font-weight: 600;
}
.disPerInput::placeholder {
  color: #344054;
  font-size: 14px;
  font-weight: 700;
}

.dis-border {
  border: 1.8px solid #dbdbdb;
}

.dis-per-focus:focus-visible {
  outline-color: #277dfe;
}
