.search_input_role {
  font-size: 12px;
}
.search_input_role:focus-visible {
  outline: none;
}
.search_input_role::placeholder {
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
}
