/* .input_container {
  border: 1px solid #d0d5dd;
}
.input_field {
  font-size: 14px;
  color: #0d111a;
}
.input_field ::placeholder {
  font-size: 12px;
  color: #667085;
} */

.inputField_border__error {
  padding: 0.39rem 0.8rem;
  border: 1.55px solid #f24444;
  border-radius: 8px;
}
.inputField_border {
  padding: 0.39rem 0.8rem;
  border: 1.55px solid #d0d5dd;
  border-radius: 8px;
}
.inputField::placeholder {
  opacity: 1;
  /* color: #667085; */
  font-family: "Inter", sans-serif !important;
  /* font-size: 14px; */
  font-weight: 400;
  position: absolute;
  top: 30%;
}
.inputField {
  box-shadow: 0px 1px 2px 0px #1018280d;
  height: 2.9rem;
  /* font-size: 14px; */
  /* color: #0d111a; */
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
}

.input-field-no-spin-buttons {
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover::-webkit-inner-spin-button,
  &:hover::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.selectField_border__error {
  border: 1.2px solid #f24444;
  border-radius: 10px;
}
.selectField_border {
  border: 1.2px solid #d0d5dd;
  border-radius: 10px;
}
.selectField {
  height: 2.9rem;
  font-family: "Inter", sans-serif !important;
  /* font-size: 15px; */
  font-weight: 500;
  padding-top: 1px;
}

.textAreaField {
  box-shadow: 0px 1px 2px 0px #1018280d;
  /* font-size: 14px; */
  /* color: #0d111a; */
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
}
.textAreaField::placeholder {
  font-family: "Inter", sans-serif !important;
  /* font-size: 14px; */
  font-weight: 400;
  position: absolute;
}
