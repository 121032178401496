.table-container {
  overflow-x: auto;
  width: 100%;
  /* max-height: 20rem; */
  scrollbar-width: thin;
  overflow-y: scroll;
}

.tab-purchase-cell {
  border-bottom: 1px solid #ebeaf2;
  padding: 18px 15px;
  text-align: left;
}
.table-container::-webkit-scrollbar {
  height: 8px;
  width: 10px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #bdbcc6;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #ebeaf2; /* Set background color */
}
.my-table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  border: 1px solid var(--light-border-color);
  padding: 12px 10px;
}

.table-header:last-of-type {
  border: 1px solid var(--light-border-color);
  background-color: var(--table-heading-background);
  padding: 12px 10px;
  text-align: center;
  position: sticky;
  z-index: 0; /* Ensure it appears above the scrolling content */
  right: 0; /* Stick to the right side */
}


.table-cell {
  border: 1px solid var(--light-border-color);
  padding: 6px 5px;
}
.table-cell:last-of-type {
  border: 1px solid var(--light-border-color);
  padding: 6px;
  position: sticky;
  right: 0;
  background-color: #ffffff;
}
.table-cell-last-column {
  border: 1px solid var(--light-border-color);
  padding: 6px;
  position: sticky;
  right: 0;
}

.table-header-billing {
  border-width: 1px 2px 1px 2px;
  border-style: solid;
  border-color: #ebeaf2;
  padding: 7px 15px;
  text-align: left;
  background-color: #f2f4f5;
}
.table-header-billing-subtotal {
  border-width: 1px 2px 0px 2px;
  border-style: solid;
  border-color: #ebeaf2;
  padding: 7px 15px;
  text-align: left;
  background-color: #f4f6f9;
}

.table-header-billing-last {
  border-width: 0px 2px 1px 0px;
  border-style: solid;
  border-color: #ebeaf2;
  padding: 7px 15px;
  text-align: left;
  background-color: #f4f6f9;
}
.table-header-billing-total {
  border-width: 1px 2px 1px 0px;
  border-style: solid;
  border-color: #ebeaf2;
  padding: 7px 15px;
  text-align: left;
  background-color: #f4f6f9;
}
.table-cell-billing {
  border-width: 1px 2px 1px 2px;
  border-style: solid;
  border-color: #ebeaf2;
  padding: 10px 15px;
  text-align: left;
}
.table-cell-billing-payment {
  border-width: 1px 2px 1px 2px;
  border-style: solid;
  border-color: #ebeaf2;
  padding: 10px 15px;
  text-align: left;
  max-width: 8rem;
}


.material-symbols {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}
.invoice-bottom-container {
  box-shadow: 100px 100px 100px 100px #1e273126;
}

.print-hide {
  display: none;
}

@media print {
  .print-hide {
    display: block !important;
  }
}
