.layout_profile {
  background: linear-gradient(
      0deg,
      rgba(40, 38, 38, 0.5),
      rgba(40, 38, 38, 0.5)
    ),
    linear-gradient(0deg, rgba(109, 109, 109, 0.5), rgba(109, 109, 109, 0.5));
}

.inner_layout_header {
  box-shadow: 0px 4px 40px -4px #474f5e0f;
  border-bottom: 1px solid #f0f0f0;
}

.sidebar_container {
  min-width: 20rem;
  max-width: 25rem;
}

/* styles.css */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* styles.scss */
.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.main_layout_container {
  width: calc(100% - 14rem);
}

.main_container_not_exp {
  width: calc(100% - 5rem);
}



/* Define custom scrollbar styles */
.scrollbar-thin-class::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.scrollbar-thin-class::-webkit-scrollbar-thumb {
  background-color: white; /* Color of the scrollbar thumb */
  border-radius: 9999px; /* Rounded corners of the scrollbar thumb */
}

.scrollbar-thin-class::-webkit-scrollbar-track {
  background-color: transparent; /* Background color of the scrollbar track */
}

