
.DisinputField::placeholder {
  opacity: 1;
  color: #667085;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 30%;
}
.DisinputField {
  box-shadow: 0px 1px 2px 0px #1018280D;
  height: 2.2rem;
  font-size: 14px;
  color: #0d111a;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
}

.table-container {
  overflow-x: auto;
  width: 100%;
  /* max-height: 20rem; */
  scrollbar-width: thin;
}
/* WebKit-based browsers (Chrome, Safari) */
.table-container::-webkit-scrollbar {
  height: 8px;
  width: 10px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #bdbcc6;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #ebeaf2; /* Set background color */
}




.table-cell-billing {
  border-width: 1px 2px 1px 2px;
  border-style: solid;
  border-color: #ebeaf2;
  padding: 10px 15px;
  text-align: left;
}
.table-cell-billing-payment {
  border-width: 1px 2px 1px 2px;
  border-style: solid;
  border-color: #ebeaf2;
  padding: 10px 15px;
  text-align: left;
  max-width: 8rem;
}

.material-symbols {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}
.invoice-bottom-container {
  box-shadow: 20px 4px 15px 0px #1e273126;
}

.print-hide {
  display: none;
}

@media print {
  .print-hide {
    display: block !important;
  }
}
