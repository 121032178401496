.price-tab-container {
  margin-top: 10px;
  overflow-x: auto;
  width: 100%;
  overflow-y: hidden; /* Only allow horizontal scrollbar */

  /* max-height: 20rem; */
  scrollbar-width: thin;
}
.price-tab-container::-webkit-scrollbar {
  height: 8px;
  width: 10px;
  position: fixed;
  bottom: 40rem;
}

.price-tab-container::-webkit-scrollbar-thumb {
  background-color: #bdbcc6;
  border-radius: 10px;
}

.price-tab-container::-webkit-scrollbar-track {
  background-color: #ebeaf2; /* Set background color */
}

.price-my-tab {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 20px;  */
}

.price-tab-header {
  border-top: 1px solid #ebeaf2;
  border-bottom: 1px solid #ebeaf2;
  padding: 12px 15px;
  text-align: left;
}

.price-tab-cell {
  border-bottom: 1px solid #ebeaf2;
  padding: 18px 15px;
  text-align: left;
}

.input_border {
  border: 1.55px solid #d0d5dd;
}




